import React from 'react';
import { makeStyles, Avatar, Box } from '@material-ui/core';
import Img from 'gatsby-image';
import Rating from '@material-ui/lab/Rating';
import PersonIcon from '@material-ui/icons/Person';
import { string, number } from 'prop-types';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: COLORS.WHITE,
    height: ({ star }) => (star ? 180 : 160),
    width: 280,
    padding: 20,
    [theme.breakpoints.down(800)]: {
      width: 250,
      margin: 0,
    },
    [theme.breakpoints.down(410)]: {
      width: 200,
      height: ({ star }) => (star ? 190 : 170),
    },
    [theme.breakpoints.down(350)]: {
      height: ({ star }) => (star ? 215 : 200),
    },
    [theme.breakpoints.down(325)]: {
      width: '100%',
    },
  },
  image: {
    width: 64,
    height: 64,
    borderRadius: '50%',
    display: 'inline-table',
  },
  avatar: {
    margin: 0,
    width: theme.spacing(8),
    height: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  },
  cardItemContainer: {
    display: 'flex',
    width: '90%',
    margin: 0,
    padding: 0,
  },
  commentContainer: {
    display: 'flex',
    marginTop: 10,
  },
  headerCardContent: {
    paddingLeft: '20px',
    width: '80%',
    ['@media (max-width:350px)']: {
      paddingLeft: 10,
    },
  },
  text: {
    margin: 2,
    lineHeight: 1,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  stars: {
    color: COLORS.RED,
    ['@media (max-width:350px)']: {
      fontSize: 15,
    },
  },
  textComment: {
    fontStyle: 'italic',
    fontSize: FONTS.SIZE.XXS,
    lineHeight: 1.2,
    '-webkit-line-clamp': 3,
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    '&:before': {
      content: '"“"',
    },
    '&:after': {
      content: '"”"',
      display: 'inline-block',
    },
  },
}));

const ReviewCard = ({
  star,
  name,
  description,
  comment,
  imgFluid,
}) => {
  const classes = useStyles({ star });
  return (
    <Box className={classes.paper} elevation={1}>
      <div className={classes.cardItemContainer}>
        {imgFluid ? (
          <Img
            className={classes.image}
            fluid={imgFluid}
            alt={name}
          />
        ) : (
          <Avatar className={classes.avatar}>
            <PersonIcon />
          </Avatar>
        )}
        <div className={classes.headerCardContent}>
          <p className={classes.text}>
            <strong>{name.toUpperCase()}</strong>
          </p>
          <p className={classes.text}>{description}</p>
          {star && (
            <Rating
              className={classes.stars}
              name="read-only"
              value={star}
              readOnly
              precision={0.5}
            />
          )}
        </div>
      </div>
      <div className={classes.commentContainer}>
        <p className={classes.textComment}>{comment}</p>
      </div>
    </Box>
  );
};

ReviewCard.propTypes = {
  star: number,
  imgFluid: string,
  name: string,
  description: string,
  comment: string,
};
ReviewCard.defaultProps = {
  star: null,
  imgFluid: '',
  name: 'No name recived',
  description: 'No team recived',
  comment: 'No comment recived',
};
export default ReviewCard;
