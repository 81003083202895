import React from 'react';
import Slider from './Slider';
import ReviewCard from '../Cards/ReviewCard';
import { arrayOf, object, shape } from 'prop-types';

const ReviewCardSlider = ({ cardsData, settings }) => {
  return (
    <>
      {/*TODO:Use random sting instead of index as key value. */}
      {cardsData && (
        <Slider settings={settings}>
          {cardsData.map(
            (
              {
                node: {
                  image,
                  comment: { comment },
                  ...props
                },
              },
              i
            ) => (
              <ReviewCard
                key={comment}
                {...props}
                imgFluid={image && image.fluid}
                comment={comment}
              />
            )
          )}
        </Slider>
      )}
    </>
  );
};

ReviewCardSlider.propTypes = {
  cardsData: arrayOf(object),
  settings: shape(),
};

export default ReviewCardSlider;
